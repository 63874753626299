<template>
    
    
      
            <div class="">
                <slot />
            </div>

  
</template>
